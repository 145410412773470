<template>
  <div class="outer-view-wrapper" v-if="site">
    <sv-section
      :sectionEnabled="true"
      id="kontakt"
      class="sv-email-form-wrapper datenschutz-wrapper"
      theme="light"
    >
      <h1 class="section-headline">
        <editable :document="$options.name" :dataEditId="`headline`"></editable>
      </h1>
      <div class="datenschutz-wrapper">
        <editable
          :document="$options.name"
          :dataEditId="`datenschutz-text`"
        ></editable>
      </div>
    </sv-section>
  </div>
</template>

<script>
import SvSection from "@/components/SvSection.vue";

export default {
  props: ["computedDbData"],
  name: "Datenschutz",
  data: () => ({
    site: null,
  }),
  components: {
    "sv-section": SvSection,
  },
  watch: {
    computedDbData: {
      immediate: true,
      handler(newVal) {
        try {
          this.site = newVal[this.$options.name];
        } catch (error) {
          console.error(error);
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/css/variables.scss";

.datenschutz-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 25px;

  .section-headline {
    margin: 0 auto 23.45px auto;
    max-width: 380px;
  }
}
</style>
